<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <rect y="6" width="14" height="2" :fill="fillColor" />
    <rect
      v-show="!active"
      x="6"
      y="14"
      width="14"
      height="2"
      transform="rotate(-90 6 14)"
      :fill="fillColor"
    />
  </svg>
</template>
<script>
  export default {
    props: {
      fillColor: {
        type: String,
        required: false,
        default: '#0384FB',
      },
      active: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>
